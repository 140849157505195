import { render, staticRenderFns } from "./yiliao.vue?vue&type=template&id=78357180&scoped=true&"
import script from "./yiliao.vue?vue&type=script&lang=js&"
export * from "./yiliao.vue?vue&type=script&lang=js&"
import style0 from "./yiliao.vue?vue&type=style&index=0&id=78357180&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78357180",
  null
  
)

export default component.exports